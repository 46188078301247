/**
 * CookieConsent
 *
 * @author Charlotte Prieß <priess@pharma4u.de>
 * @module CookieConsent
 * @see https://www.osano.com/cookieconsent/documentation/about-cookie-consent/
 */
const CookieConsent = ((window) => {
    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        attribute: {
            color: {
                "popupBackground": "#f5f5f5",
                "popupText":       "#444444"
            }
        },
        textMessage: {
            "messagelink":    '<p id="cookieconsent:desc" class="p-y-xs m-r-xl">{{message}} <a aria-label="learn more about cookies" tabindex="0" class="c-link -icon-right -icon-arrow" href="{{href}}" target="_blank">{{link}}</a></p>',
            "dismiss":        '<a aria-label="dismiss cookie message" tabindex="0" class="cc-btn cc-dismiss p-y-reset c-btn -large -primary">{{dismiss}}</a>',
            "allow":          '<a aria-label="allow cookies message" tabindex="0" class="cc-btn cc-allow p-y-reset c-btn -large -primary">{{allow}}</a>',
            "deny":           '<a aria-label="deny cookies message" tabindex="0" class="cc-btn cc-deny p-y-reset c-btn -large -primary">{{deny}}</a>',
            "link":           '<a aria-label="learn more about cookies" tabindex="0" class="cc-link c-link" href="{{href}}" target="_blank">{{link}}</a>',
            "close":          '<span aria-label="dismiss cookie message" tabindex="0" class="c-close">{{close}}</span>',
            "contentMessage": "Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Sie können nicht essenzielle Cookies akzeptieren oder ablehnen.<br>Weitere Informationen zu Cookies erhalten Sie in unserer ",
            "contentDismiss": "Verstanden",
            "contentAllow":   "Cookies akzeptieren",
            "contentDeny":    "Cookies ablehnen",
            "contentLink":    "Datenschutzerklärung",
            "contentHref":    "https://www.pharma4u.de/allgemein/rechtliches/datenschutz/"

        }

    };

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} Konfiguration
     */
    let config = {};

    /**
     * @type {object} Öffentliche Members
     */
    const exports = {};

    /**
     * @param settings
     */
    exports.init = (settings) => {
        config = $.extend(true, {}, defaults, settings);

        /**
         * Cookie Disclaimer
         */
        window.addEventListener("load", function () {
            window.cookieconsent.initialise({
                position:         "bottom",
                revokeBtn:        '<div class="cc-revoke p-y-reset c-btn -large -primary" style="bottom:26px;right:76px;text-decoration: none;position:fixed;">Cookie-Einwilligungen</div>',
                revokable:        !0,
                animateRevokable: !1,
                type:             "opt-in",
                compliance:       {
                    info:      '<div class="cc-compliance">{{dismiss}}</div>',
                    "opt-in":  '<div class="cc-compliance">{{deny}}{{allow}}</div>',
                    "opt-out": '<div class="cc-compliance">{{deny}}{{dismiss}}</div>'
                },
                palette: {
                    popup: {
                        background: config.attribute.color.popupBackground,
                        text:       config.attribute.color.popupText
                    }
                },
                elements: {
                    messagelink: config.textMessage.messagelink,
                    dismiss:     config.textMessage.dismiss,
                    allow:       config.textMessage.allow,
                    deny:        config.textMessage.deny,
                    link:        config.textMessage.link,
                    close:       config.textMessage.close
                },
                content: {
                    message: config.textMessage.contentMessage,
                    dismiss: config.textMessage.contentDismiss,
                    deny:    config.textMessage.contentDeny,
                    allow:   config.textMessage.contentAllow,
                    link:    config.textMessage.contentLink,
                    href:    config.textMessage.contentHref
                }
            });
        });
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */
    return exports;
})(window);

