/**
 * @module Panel
 * @author "Laura Redeker" <redeke@pharma4u.de>
 * TODO: implement SEAF Configuration Pattern
 * TODO: use ARIA- oder data-attributes as selectors (bigger refactoring!)
 *
 * Configuration Options (add these classes to c-panel element)
 * ----------------------------------------------------------------
 *    - Panel is a Dropdown:                    .-is-dropdown
 *    - OR Panel is a Modal                     .-is-modal
 *    - Panel has a Backdrop-Layer:             .-has-backdrop
 *    - Panel has a X-Close-Button:             .-has-close-btn
 *    - Panel closes on backdrop-click:         .-close-backdrop
 *    - add Class "js-abort" to the close-btn   .-close-js-abort
 * ----------------------------------------------------------------
 *
 * Optional Data-Attributes for Trigger-Elements
 * ----------------------------------------------------------------
 *    - Close current Panel:                    [data-dismiss='modal']
 *    - TODO: add option to close ALL open Panels per data-attribute
 * ----------------------------------------------------------------
 *
 * @type {{init: init}}
 */
const Panel = ((document, $) => {
    const settings = {
        selector: {
            panel:             ".c-panel",
            panelInner:        ".c-panel__inner",
            panelIsVisible:    ".c-panel.-is-visible",
            panelCloseTrigger: "[data-dismiss='modal']",
            panelBackdrop:     ".c-panel__backdrop",
            panelCloseBtn:     ".c-panel__inner__close",
            openDropdowns:     ".c-panel.-is-visible.-is-dropdown",
            selectList:        ".c-list__item",
            themePlausi:       ".theme-plausi"
        },
        class: {
            triggerIsActive:      "-is-active",
            panelIsVisible:       "-is-visible",
            panelCloseBtn:        "c-panel__inner__close",
            panelHasCloseBtn:     "-has-close-btn",
            closeBtnAbort:        "-close-js-abort",
            jsAbort:              "js-abort",
            panelHasBackdrop:     "-has-backdrop",
            panelBackdrop:        "c-panel__backdrop",
            panelCloseByBackdrop: "-close-backdrop",
            panelIsDropdown:      "-is-dropdown",
            modalIsOpen:          "-modal-open",
            themePlausi:          "theme-plausi"
        },
        text: {
            panelClose: "Fenster schließen"
        },
        event: {
            open:   "panel:open",
            close:  "panel:close",
            toggle: "panel:toggle"
        }
    };

    /**
     * Closes any open panel and updates trigger.
     *
     * @param {Node} panel - The panel that is toggled.
     * @param {Node} trigger - The element that triggers the panel.
     */
    const close = (panel, trigger) => {
        if (trigger) {
            trigger.classList.remove(settings.class.triggerIsActive);
        }

        // close current panel
        panel.classList.remove(settings.class.panelIsVisible);
        const openPanels = document.querySelectorAll(settings.selector.panelIsVisible);

        // allow body scrolling if last panel is closed
        if (openPanels.length <= 1) {
            document.body.classList.remove(settings.class.modalIsOpen);
        }

        // close event
        const closeEvent = new CustomEvent(settings.event.close, {
            'detail': {
                trigger: trigger
            }
        });

        panel.dispatchEvent(closeEvent);
    };

    /**
     * Opens and sets up the panel with all possible display/closing options.
     *
     * @param {Node} panel - The panel that is toggled.
     * @param {Node} trigger - The element that triggers the panel.
     */
    const setup = (panel, trigger) => {
        const panelInner = panel.querySelector(settings.selector.panelInner),
            closeOnBackdropClick = panel.classList.contains(settings.class.panelCloseByBackdrop),
            hasBackdropClass = panel.classList.contains(settings.class.panelHasBackdrop),
            hasCloseBtnClass = panel.classList.contains(settings.class.panelHasCloseBtn),
            closeBtnHasAbortClass = panel.classList.contains(settings.class.closeBtnAbort),
            hasCloseBtn = panel.querySelector(settings.selector.panelCloseBtn),
            closeTriggers = panel.querySelectorAll(settings.selector.panelCloseTrigger),
            isDropdown = panel.classList.contains(settings.class.panelIsDropdown),
            isPlausi = $(trigger).parents(settings.selector.themePlausi).length;

        /**
         * close panel and remove backdrop
         */
        const closePanelRemoveBackdrop = () => {
            close(panel, trigger);
            const backdrop = document.querySelector(`[data-panel="${panel.id}"]`);

            if (document.body.contains(backdrop)) {
                backdrop.parentNode.removeChild(backdrop);
            }
        };

        /**
         * add backdrop to document.body
         *
         * @param {Node} panel - The panel that is toggled.
         */
        const addBackdrop = (panel) => {
            const backdropHTML = `<div class="${settings.class.panelBackdrop}" data-panel="${panel.id}" title="${settings.text.panelClose}"></div>`;
            const existingBackdrop = document.querySelector(settings.selector.panelBackdrop);

            // add backdrop if it doesn't exist already
            if (!(existingBackdrop && existingBackdrop.dataset.panel === panel.id)) {
                panel.insertAdjacentHTML('beforebegin', backdropHTML);

                // select current backdrop
                const newBackdrop = document.querySelector(`${settings.selector.panelBackdrop}[data-panel="${panel.id}"]`);

                // make backdrop visible if class is set
                if (hasBackdropClass) {
                    newBackdrop.classList.add(settings.class.panelIsVisible);
                }

                // optional panel setting: close on backdrop click
                if (closeOnBackdropClick) {
                    newBackdrop.addEventListener('click', function () {
                        closePanelRemoveBackdrop();
                    });
                }
            }
        };

        /**
         * Set up specific modal and dropdown settings
         * e.g. prevent body scrolling when modal is opened
         */
        if (isDropdown) {
            addBackdrop(panel);
        } else {
            document.body.classList.add(settings.class.modalIsOpen);

            /*
             * adds theme class if button is in plausi
             */
            if (isPlausi) {
                panel.classList.add(settings.class.themePlausi);
            }

            // optional panel setting: close on backdrop click
            if (closeOnBackdropClick) {
                panel.addEventListener('click', function (e) {
                    if (!$(e.target).closest($(settings.selector.panelInner)).length && !$(e.target).is($(settings.selector.panelInner))) {
                        closePanelRemoveBackdrop();
                    }
                });
            }
        }

        // add close button to the panel if it doesn't exist already
        if (hasCloseBtnClass && !hasCloseBtn) {
            const panelCloseBtnHTML = `<a class="c-link ${settings.class.panelCloseBtn} ${closeBtnHasAbortClass ? settings.class.jsAbort : ''}" title="${settings.text.panelClose}" aria-label="${settings.text.panelClose}" role="button"></a>`;
            panelInner.insertAdjacentHTML('afterbegin', panelCloseBtnHTML);
            const closeBtn = panel.querySelector(settings.selector.panelCloseBtn);
            closeBtn.addEventListener('click', closePanelRemoveBackdrop);
        }

        // close panel on click on a "close-trigger" element ([data-dismiss='modal'])
        if (closeTriggers) {
            closeTriggers.forEach((trigger) => {
                trigger.addEventListener('click', closePanelRemoveBackdrop);
            });
        }
    };

    /**
     * Close all open dropdown panels
     */
    const closeAllOpenDropdowns = () => {
        const openDropdowns = document.querySelectorAll(settings.selector.openDropdowns);
        if (openDropdowns.length > 0) {
            openDropdowns.forEach((openDropdown) => {
                const activeTrigger = document.querySelector(`[data-target="${'#' + openDropdown.id}"]`);
                close(openDropdown, activeTrigger);
            });
        }
    };

    /**
     * Opens the panel and closes open dropdown panels
     *
     * @param {Node} panel - The panel that is toggled.
     * @param {Node} trigger - The element that triggers the panel.
     */
    const open = (panel, trigger) => {
        closeAllOpenDropdowns();

        // open current Panel
        panel.classList.add(settings.class.panelIsVisible);
        panel.focus();
        setup(panel, trigger);

        // open event
        const openEvent = new CustomEvent(settings.event.open, {
            'detail': {
                trigger: trigger
            }
        });

        panel.dispatchEvent(openEvent);
    };

    /**
     * Registers events on trigger and toggles the panel.
     *
     * @param {Node} panel - The panel that is toggled.
     * @param {Node} trigger - The element that triggers the panel.
     */
    const togglePanelDisplay = (panel, trigger) => {
        trigger.addEventListener('click', function (e) {
            if (e.currentTarget.classList.contains(settings.class.triggerIsActive)) {
                close(panel, trigger);
            } else {
                open(panel, trigger);
            }
        });

        // toggle event
        const toggleEvent = new CustomEvent(settings.event.toggle, {
            'detail': {
                trigger: trigger
            }
        });

        panel.dispatchEvent(toggleEvent);
    };

    /**
     * get DOM elements and initialize Panel module
     */
    const init = () => {
        const panelElement = document.querySelectorAll(settings.selector.panel);

        panelElement.forEach((panel) => {
            const panelSelector = '#' + panel.id,
                triggerString = `[data-target="${panelSelector}"]`,
                triggers = document.querySelectorAll(triggerString);

            if (triggers) {
                triggers.forEach((trigger) => {
                    togglePanelDisplay(panel, trigger);
                });
            }
        });
    };

    return {
        init:        init,
        open:        open,
        close:       close,
        togglePanel: togglePanelDisplay
    };
})(document, $);
