/**
 * Chart
 *
 * @author Laura Redeker <redeker@pharma4u.de>
 * @module Chart
 * TODO: zur zeit hard gecodete hex-codes (und nur pharma4u rot)
 */
const Chart = (($) => {
    /**
     * @callback SampleModuleUpdate
     * @param {Element} text
     */

    /**
     *  Module Settings
     *
     * @typedef {object} Settings
     * @type {object}
     * @property {object} selector DOM-Selektor(en)
     * @property {object} class Verwendete CSS-Klassen
     * @property {object} attribute HTML-Attribute (z.B. data, aria)
     * @property {object} callback Callback-Funktionen
     * @property {SampleModuleUpdate} callback.update Callback für Modul-Update
     */

    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        selector: {
            chart: ".js-chart"
        },
        class:     {},
        attribute: {},
        options:   {
            scaleColor: false,
            trackColor: '#eee',
            barColor:   '#c00000',
            lineWidth:  3,
            lineCap:    'butt',
            size:       36 // must be same as in CSS
        }
    };

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} Konfiguration
     */
    let config = {};

    /**
     * @type {object} Öffentliche Members
     */
    const exports = {};

    /*
     * Initialize the module with custom settings
     *
     * @method
     * @public
     * @param {Settings} settings - The settings that will overwrite the default settings
     */
    exports.init = (settings) => {
        config = $.extend(true, {}, defaults, settings);

        if ($(config.selector.chart).length) {
            $(config.selector.chart).easyPieChart(config.options);
        } else {
            return;
        }
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */
    return exports;
})($);
