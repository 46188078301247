/**
 * @module Form
 * @author "Laura Redeker" <redeke@pharma4u.de>
 *
 * Handles general functionality for inputs, textareas etc.
 * e.g.: floating labels, clear search input button, "data-activate-btn" disabled button
 *
 */
const Form = (() => {
    const settings = {
        selector: {
            floatContainer:      ".c-float-container",
            floatElements:       "input, select, textarea",
            searchInput:         ".js-search",
            searchClear:         ".js-search-clear",
            select2:             ".js-select2",
            activateWithContent: "data-activate-btn",
            inlineBtn:           ".c-btn"
        },
        class: {
            isActive:         "-is-active",
            isFocused:        "-is-focused",
            hasChanged:       "-has-changed",
            isSelect:         "-has-select",
            hasDatepicker:    "-has-datepicker",
            hasTooltip:       "js-tooltip",
            select2Container: "select2-container",
            select2Active:    "select2-container--open"
        },
        attribute: {
            isDisabled:         "disabled",
            minAmountOfLetters: "data-min-amount"
        }
    };

    // UI FUNCTIONS (Internal) =================================================

    /**
     * handles the blur event on inputs
     *
     * @param {Node} el - input element
     */
    const handleBlur = (el) => {
        el.closest(settings.selector.floatContainer).classList.remove(settings.class.isFocused);

        if (!el.value) {
            el.removeAttribute('placeholder');
            el.closest(settings.selector.floatContainer).classList.remove(settings.class.isFocused);
            el.closest(settings.selector.floatContainer).classList.add(settings.class.hasChanged);
        }
    };

    /**
     * deactivate floating label
     *
     * @param {Node} el - the input element
     */
    const deactivateFloat = (el) => {
        el.closest(settings.selector.floatContainer).classList.remove(settings.class.isActive);
        el.removeAttribute('placeholder');
    };

    /**
     * activates floating label
     *
     * @param {Node} el - the input element
     */
    const activateFloat = (el) => {
        const placeholderText = el.getAttribute('data-placeholder') || "";
        $(el).closest(settings.selector.floatContainer).addClass(settings.class.isActive);
        el.setAttribute('placeholder', placeholderText);
    };

    /**
     * check content of input element
     *
     * @param {Node} el - the input element
     */
    const checkInput = (el) => {
        // check if value empty
        if (el.value && el.value.length) {
            activateFloat(el);
        } else {
            deactivateFloat(el);
        }
    };

    /**
     * handles the focus event on inputs
     *
     * @param {Node} el - input element
     */
    const handleFocus = (el) => {
        activateFloat(el);
        el.closest(settings.selector.floatContainer).classList.add(settings.class.isFocused);
    };

    /**
     * Activate disabled inline button
     *
     * @param {Node} btn - the inline button
     */
    const activateInlineBtn = (btn) => {
        btn.removeAttribute(settings.attribute.isDisabled);

        if (btn.classList.contains(settings.class.tooltip)) {
            btn.trigger('mouseover');
        }
    };

    /**
     * Disable active inline button
     *
     * @param {Node} btn - the inline button
     */
    const disableInlineBtn = (btn) => {
        btn.setAttribute(settings.attribute.isDisabled, true);
    };

    /**
     * Hide "clear" button
     *
     * @param {Node} btn - the clear "X" button
     * @param {boolean} hasActivateWithContent - has disabled inline btn
     */
    const inactivateClearBtn = (btn, hasActivateWithContent) => {
        if (btn) {
            btn.classList.remove(settings.class.isActive);

            if (hasActivateWithContent) {
                const inlineBtn = btn.closest(settings.selector.floatContainer).querySelector(settings.selector.inlineBtn);
                disableInlineBtn(inlineBtn);
            }
        }
    };

    /**
     * Clear input field on click
     *
     * @param {Node} btn - the clear "X" button
     * @param {Node} input - the input element
     * @param {boolean} hasActivateWithContent - has disabled inline btn
     */
    const clearInput = (btn, input, hasActivateWithContent) => {
        inactivateClearBtn(btn, hasActivateWithContent);
        input.value = "";
        input.focus();
    };

    /**
     * Display "clear" button
     *
     * @param {Node} btn - the clear "X" button
     * @param {Node} input - the input element
     * @param {boolean} hasActivateWithContent - has disabled inline btn
     */
    const activateClearBtn = (btn, input, hasActivateWithContent) => {
        if (btn) {
            btn.classList.add(settings.class.isActive);
            btn.addEventListener('click', function () {
                clearInput(btn, input, hasActivateWithContent);
            });
        }
    };

    /**
     * Handles disabled button that is activated by input content
     *
     * @param {Node} element - the current input
     */
    const handleDisabledInlineBtn = (element) => {
        // if it has a disabled button that is activated by content
        const floatContainer = element.closest(settings.selector.floatContainer),
            inlineBtn = floatContainer.querySelector(settings.selector.inlineBtn),
            minAmountOfLetters = floatContainer.getAttribute(settings.attribute.minAmountOfLetters),
            str = element.value.replace(/\s+/g, '');

        if (str.length >= minAmountOfLetters) {
            activateInlineBtn(inlineBtn);
        } else {
            disableInlineBtn(inlineBtn);
        }
    };

    /**
     * Bind all input events
     *
     * @param {Node} element - the input/select/textarea.. element
     * @param {boolean} hasActivateWithContent - has disabled inline btn
     * @param {boolean} hasDatepicker - has datepicker
     */
    const bindEvents = (element, hasActivateWithContent, hasDatepicker) => {
        element.addEventListener('input', function () {
            checkInput(element);

            if (hasActivateWithContent) {
                handleDisabledInlineBtn(element);
            }
        });

        element.addEventListener('change', function () {
            checkInput(element);
            element.closest(settings.selector.floatContainer).classList.add(settings.class.hasChanged);

            if (hasActivateWithContent) {
                handleDisabledInlineBtn(element);
            }
        });

        element.addEventListener('click', function () {
            activateFloat(element);
        });

        element.addEventListener('focus', function () {
            handleFocus(element);
        });

        element.addEventListener('blur', function () {
            if (!hasDatepicker) {
                checkInput(element);
            }

            handleBlur(element);
        });
    };

    /**
     *
     * register events for search inputs
     *
     * @param {Node} element - the input element
     * @param {boolean} hasActivateWithContent - has disabled inline btn
     */
    const bindActivateEvents = (element, hasActivateWithContent) => {
        const clearInputBtn = element.closest(settings.selector.floatContainer).querySelector(settings.selector.searchClear);

        element.addEventListener('input', function () {
            if (element.value) {
                activateClearBtn(clearInputBtn, element, hasActivateWithContent);
            } else {
                inactivateClearBtn(clearInputBtn, hasActivateWithContent);
            }
        });

        element.addEventListener('click', function () {
            activateClearBtn(clearInputBtn, element, hasActivateWithContent);
        });

        element.addEventListener('blur', function () {
            if (!element.value.length) {
                inactivateClearBtn(clearInputBtn, hasActivateWithContent);
            }
        });
    };

    /**
     * initialize Form Module
     * get DOM elements
     */
    const init = () => {
        const floatContainers = document.querySelectorAll(settings.selector.floatContainer);
        floatContainers.forEach((container) => {
            const floatInputs = container.querySelectorAll(settings.selector.floatElements);

            floatInputs.forEach((input) => {
                const searchInput = container.querySelector(settings.selector.searchInput),
                    hasDatepicker = container.classList.contains(settings.class.hasDatepicker),
                    hasSelect = container.classList.contains(settings.class.isSelect),
                    hasActivateWithContent = container.hasAttribute(settings.selector.activateWithContent);

                if (input) {
                    // general floating label and input events
                    bindEvents(input, hasActivateWithContent, hasDatepicker);

                    // activate float if input has content
                    if (input.value || hasSelect) {
                        activateFloat(input);

                        // if it's a search field
                        if (searchInput) {
                            const clearInputBtn = container.querySelector(settings.selector.searchClear);
                            activateClearBtn(clearInputBtn, searchInput);
                        }
                    }
                }

                // if input is a search field with clear button
                if (searchInput) {
                    bindActivateEvents(searchInput, hasActivateWithContent);
                }
            });
        });
    };

    return {
        init:              init,
        activateFloat:     activateFloat,
        deactivateFloat:   deactivateFloat,
        activateInlineBtn: activateInlineBtn
    };
})();
