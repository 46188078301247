/**
 * Notification
 *
 * @author Laura Redeker <redeker@pharma4u.de>
 * @module Notification
 */
const Notification = (() => {
    /**
     * @callback SampleModuleUpdate
     * @param {Element} text
     */

    /**
     *  Module Settings
     *
     * @typedef {object} Settings
     * @type {object}
     * @property {object} selector DOM-Selektor(en)
     * @property {object} class Verwendete CSS-Klassen
     * @property {object} attribute HTML-Attribute (z.B. data, aria)
     * @property {object} callback Callback-Funktionen
     * @property {SampleModuleUpdate} callback.update Callback für Modul-Update
     */

    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        selector: {
            notification:         ".c-notification",
            notificationCloseBtn: ".js-close-layer",
            ieLayer:              ".js-ie-layer"
        },
        class: {
            hideLayer: "-hidden"
        },
        cookie: {
            ieCookieName: 'ie_notice'
        }
    };

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} Konfiguration
     */
    let config = {};

    /**
     * Open Layer Event
     *
     * @param {Node} layer - the notification layer
     */
    const showLayer = (layer) => {
        if (layer.classList.contains(config.class.hideLayer)) {
            layer.classList.remove(config.class.hideLayer);
        }
    };

    /**
     * Close Layer Event
     *
     * @param {Node} layer - the notification layer
     * @param layerCloseBtn
     */
    const hideLayer = (layer) => {
        if (!layer.classList.contains(config.class.hideLayer)) {
            layer.classList.add(config.class.hideLayer);
        }
    };

    /**
     * Handles the IE warning layer
     */
    const showIEWarningLayer = () => {
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode,
            ieLayer = document.querySelector(config.selector.ieLayer),
            ieLayerCloseBtn = ieLayer.querySelector(config.selector.notificationCloseBtn);

        if (isIE11) {
            if ($.cookie(config.cookie.ieCookieName) === '1') {
                hideLayer(ieLayer);
            } else {
                // display layer if cookie has not been set
                showLayer(ieLayer);

                $(ieLayerCloseBtn).on('click', function() {
                    $.cookie(config.cookie.ieCookieName, 1, {
                        expires: 1, // expires after one day
                        path:    '/'
                    });
                });
            }
        }
    };

    /**
     * Close-Btn functionality
     *
     * @param {Node} layer - The notification layer
     */
    const handleCloseBtn = (layer) => {
        const layerCloseBtn = layer.querySelector(config.selector.notificationCloseBtn);

        if (layerCloseBtn) {
            $(layerCloseBtn).on("click", function() {
                hideLayer(layer);
            });
        }
    };

    /*
     * Initialize the module with custom settings
     *
     * @method
     * @public
     * @param {Settings} settings - The settings that will overwrite the default settings
     */
    const init = (settings) => {
        config = $.extend(true, {}, defaults, settings);
        const notificationElements = document.querySelectorAll(config.selector.notification);

        if (!notificationElements.length) {
            return;
        }

        notificationElements.forEach((notificationLayer) => {
            handleCloseBtn(notificationLayer);
        });

        showIEWarningLayer();
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */

    return {
        init:      init,
        showLayer: showLayer,
        hideLayer: hideLayer
    };
})();
