/**
 * Select2 with Version 4.1.0-rc.0
 *
 *
 * @author "Laura Redeker" <redeke@pharma4u.de>
 * @type {{init: init}}
 * @property {object} selector
 * @module Select
 */

const Select = (($) => {
    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        selector: {
            selectField: ".js-select2",
            searchField: ".select2-search__field"
        },
        class: {
            jsClass:             "js-select2",
            defaultClass:        "c-select",
            isDisabled:          "-disabled",
            hideDisabledOptions: '-hide-disabled-options',
            hiddenAccessible:    "select2-hidden-accessible"
        }
    };

    const MAX_CHARACTER_LENGTH = 80;

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} Konfiguration
     */
    let config = {};

    /**
     * @type {object} Öffentliche Members
     */
    const exports = {};

    /**
     *
     * @param text
     * @param unknownTerm
     * @returns {*|jQuery|HTMLElement} Markup of the new tag
     */
    const getNewTagMarkup = (text, unknownTerm) => {
        // siehe createTag, Nutzereingabe nicht aus der Liste
        return $(
            "<div class='-custom-option m-t-s'><span class='h-block h-small m-b-xs'><strong class=''>" + unknownTerm + ":</strong></span>" +
            text +
            "</span>" +
            "</div>" +
            "<div class='h-extra-small h-type-align-center h-bg-color-white p-t-xxs p-r-s h-color-disabled'>" +
            "<i class=\"fa-solid fa-arrow-turn-down-left m-r-xs\"></i>" +
            "Eingabe mit Enter bestätigen</div>"
        );
    };

    /**
     * formatting of dropdown options
     *
     * @param result
     * @param unknownTerm
     * @param element
     * @returns {*|Window.jQuery|HTMLElement}
     */
    exports.formatResult = (result, unknownTerm) => {
        if (result.newTag) {
            return getNewTagMarkup(result.text, unknownTerm);
        } else {
            return $("<span>" + result.text + "</span>");
        }
    };

    /**
     * formatting of dropdown options
     *
     * @param result
     * @param unknownTerm
     * @param element
     * @returns {*|Window.jQuery|HTMLElement}
     */
    exports.formatResultWithAdditionalInfo = (result, unknownTerm) => {
        if (result.newTag) {
            return getNewTagMarkup(result.text, unknownTerm);
        } else {
            let markup = '<span class="title">' + result.text + '</span>';
            if (result.info) {
                let info = result.info;
                if (info.length > MAX_CHARACTER_LENGTH) {
                    info = info.substring(0, MAX_CHARACTER_LENGTH) + '...';
                }
                markup += '<span class="info">' + info + '</span>';
            }
            return markup;
        }
    };

    /**
     * formatting of selected option
     *
     * @param result
     * @param unknownTerm
     * @returns {string|*|jQuery|HTMLElement}
     */
    exports.formatSelection = (result, unknownTerm) => {
        if (!result.id) { // entspricht Placeholder
            return result.text;
        }

        if (result.newTag) {
            // siehe createTag, Nutzereingabe nicht aus der Liste
            return $(
                "<span>" +
                result.text +
                " <span class='h-color-gray'>(" + unknownTerm + ")</span></span>"
            );
        } else {
            if (result.info) {
                return $("<span>" + result.text +
                    " <span class='h-color-gray'>(" + result.info + ")</span></span>"
                );
            } else {
                return $("<span>" + result.text + "</span>");
            }
        }
    };

    /**
     * @param event
     * @param $element
     */
    const handleReturnKey = ($element, event) => {
        const hasHighlightedResults = $element.find(".select2-results__option--highlighted").length > 0;
        if (event.which === 13 && !hasHighlightedResults) {
            $element.val($element.find(config.selector.searchField).val());
            $element.trigger("change");
            $element.select2("close");
        }
    };

    /**
     * handles all key events
     *
     * @param $element
     */
    const handleKeys = ($element) => {
        $(document).on("select2:open", (e) => {
            // focus the search field on open
            document.querySelector(".select2-container--open .select2-search__field").focus();

            // add key handler to search field
            $(document).on("keypress", config.selector.searchField, function (event) {
                handleReturnKey($element, event);
            });
        });

        // event handler must be removed on close, so it doesn't conflict with ENTER for the default selection of an option
        $(document).on("select2:close", () => {
            $(document).off(config.selector.searchField, handleReturnKey);
        });
    };

    $.fn.select2.amd.define(
        "CustomHighlighting",
        ["select2/dropdown/tagsSearchHighlight"],
        function (TagsSearchHighlight) {
            TagsSearchHighlight.prototype.highlightFirstItem = () => {
                return;
            };
        }
    );

    /**
     * Adds new option tag to a select field if it doesn't exist already
     * can be used inside processResults of an ajax request
     *
     * @param $select jQuery selected select field
     * @param {object} resultObj single data object
     */
    exports.addOptionTagsIfNotExists = ($select, resultObj) => {
        /*
         * see https://select2.org/programmatic-control/add-select-clear-items#preselecting-options-in-an-remotely-sourced-ajax-select2
         * and https://select2.org/programmatic-control/add-select-clear-items#create-if-not-exists
         * needed for val()
         */
        if (!$select.find("option[value='" + resultObj.text + "']").length) {
            const newOption = new Option(resultObj.text, resultObj.text, false, false);
            $select.append(newOption).trigger('change');
        }
    };

    /**
     * @returns default settings for standard select-dropdowns
     */
    exports.getDefaultOptions = () => {
        return {
            minimumResultsForSearch: -1, // hide search box hack = minimum number of results that must be initially populated in order to keep the search field
            allowHtml:               true,
            allowClear:              false,
            width:                   "100%",
            closeOnSelect:           true,
            tags:                    false,
            language:                "de",
            templateResult:          Select.formatResult,
            templateSelection:       Select.formatSelection
        };
    };

    /**
     * @returns extended settings for select-dropdowns with search
     */
    exports.getOptionsWithSearch = () => {
        return {
            allowHtml:          true,
            allowInputAsChoice: false,
            allowClear:         true,
            width:              "100%",
            closeOnSelect:      true,
            tags:               false,
            placeholder:        "Bitte wählen ...",
            language:           "de",
            templateSelection:  Select.formatSelection,
            templateResult:     function (result) {
                return Select.formatResultWithAdditionalInfo(result);
            },
            escapeMarkup: function (markup) {
                return markup;
            },
            minimumResultsForSearch: 3 // at least 3 results must be available to display the search
        };
    };

    /**
     * @returns extended settings for select-dropdowns with search and custom tags
     * @param {string} unknownTerm - optional parameter that describes the type of the "unknown custom tag" that is being added
     */
    exports.getOptionsWithTags = (unknownTerm = "Unbekannter Stoff") => {
        return {
            allowHtml:               true,
            allowInputAsChoice:      false,
            allowClear:              true,
            width:                   "100%",
            closeOnSelect:           true,
            language:                "de",
            minimumInputLength:      1,
            placeholder:             "Bitte wählen ...",
            debug:                   false,
            minimumResultsForSearch: 3, // at least 3 results must be available to display the search
            templateResult:          function (result) {
                return Select.formatResultWithAdditionalInfo(result, unknownTerm);
            },
            templateSelection: function (result) {
                return Select.formatSelection(result, unknownTerm);
            },
            tags:            true, // erlaubt eigene Nutzereingaben
            dropdownAdapter: $.fn.select2.amd.require("CustomHighlighting"),
            escapeMarkup:    function (markup) {
                return markup;
            },
            createTag: function (tag) {
                // add custom user inputs, important for the select2 data format is id and text
                return {
                    id:     tag.term,
                    text:   tag.term,
                    newTag: true
                };
            },
            // You may control the placement of the newly created option by defining a insertTag callback:
            insertTag: function (data, tag) {
                // add custom/unknown option at the end
                data.push(tag);
                return data;
            }
        };
    };

    /**
     * Initializes Select2 on a specific $element with custom options
     *
     * @param $element
     * @param options
     */
    exports.apply = ($element, options = Select.getDefaultOptions()) => {
        const customClasses = $element.data("additional-classes");

        $element
            .select2(options)
            .data("select2")
            .$container.addClass("c-select")
            .addClass(customClasses);

        handleKeys($element);
    };

    /**
     * Destroys the Select2 control
     *
     * The destroy method will remove the Select2 widget from the target element
     * It will revert back to a standard select control
     *
     * @param $element
     */
    exports.destroy = ($element) => {
        $element.select2("destroy");
    };

    /**
     * Initialize the module with custom settings
     *
     * @function
     * @public
     * @param {Settings} settings - The settings that will overwrite the default settings
     */
    exports.init = (settings) => {
        config = $.extend(true, {}, defaults, settings);
        const $selectFields = $(config.selector.selectField);

        if (!$selectFields.length) {
            return;
        }

        $selectFields.get().forEach((element) => {
            if ("search" in element.dataset) {
                Select.apply($(element), Select.getOptionsWithSearch());
            } else if ("customOptions" in element.dataset) {
                Select.apply($(element), Select.getOptionsWithTags());
            } else if ("hideDisabled" in element.dataset) {
                const mergedOptions = $.extend(true, {}, Select.getDefaultOptions(), {
                    dropdownCssClass: config.class.hideDisabledOptions
                });
                Select.apply($(element), mergedOptions);
            } else {
                Select.apply($(element), Select.getDefaultOptions());
            }
        });
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */
    return exports;
})($);
