/**
 * Patternlab Facade
 */
const Patternlab = (() => {
    return {
        init: () => {
            Util.init();
            Polyfill.init();
            Accordion.init();
            Autocomplete.init();
            Chart.init();
            Datepicker.init();
            Navigation.init();
            Panel.init();
            Tooltip.init('data-title');
            ShowMore.init();
            Select.init();
            LoginModal.init();
            Notification.init();
            SelectList.init();
            Spinner.init();
            Tab.init();
            Collapse.init();
            Form.init(); // Form has to be after select
            Validate.init();

            document.dispatchEvent(new CustomEvent('patternlab:initialized'));
        }
    };
})();

/**
 * Initialize all Modules here
 */
$(window).on('load', function () {
    Patternlab.init();
});
