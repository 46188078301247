/**
 * jquery UI Autocomplete
 */
const Autocomplete = (($) => {
    /**
     * Initializes autocomplete
     */
    const init = () => {
        // TODO
    };

    return {
        init: init
    };
})(jQuery);
