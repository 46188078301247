/**
 * @module ShowMore
 * @author "Laura Redeker" <redeke@pharma4u.de>
 *
 * Show more items in a c-list
 *
 */
const ShowMore = (() => {
    const settings = {
        animationDuration: 1,
        defaultValueItems: 4,
        selector:          {
            trigger:         "[data-toggle='collapse']",
            triggerText:     ".c-link__text",
            triggerIsActive: ".-is-active",
            visibleItems:    ".-is-visible",
            hiddenItems:     ".-is-hidden"
        },
        class: {
            triggerIsActive: "-is-active",
            itemIsHidden:    "-is-hidden",
            itemIsVisible:   "-is-visible"
        },
        attribute: {
            defaultCount: "data-visible-items",
            trigger:      "data-toggle='collapse'"
        }
    };

    /**
     * switch content of trigger-text element with alternative text of data-alttext
     * and vice versa
     *
     * @param {Node} trigger - the trigger element
     */
    const toggleTrigger = (trigger) => {
        const triggerTextContainer = trigger.querySelector(settings.selector.triggerText);

        // switch text contents
        if (triggerTextContainer) {
            const oldTextString = triggerTextContainer.innerHTML;
            triggerTextContainer.innerHTML = trigger.dataset.alttext;
            trigger.dataset.alttext = oldTextString;
        }

        // toggle classes
        if (trigger.classList.contains(settings.class.triggerIsActive)) {
            trigger.classList.remove(settings.class.triggerIsActive);
        } else {
            trigger.classList.add(settings.class.triggerIsActive);
        }
    };

    /**
     *
     * @param {NodeList} list - the list elements with hidden items
     */
    const showMoreItems = (list) => {
        const hiddenListItems = list.querySelectorAll(settings.selector.hiddenItems);

        hiddenListItems.forEach((item, index) => {
            item.style.height = 0; // update the max-height
            setTimeout(() => {
                const itemHeight = Util.getHeight(item); // get natural height of element
                item.classList.remove(settings.class.itemIsHidden);
                item.classList.add(settings.class.itemIsVisible); // make item visible
                item.style.height = itemHeight; // update the max-height
            }, index * 100);
        });
    };

    /**
     *
     * @param {NodeList} list - the list elements
     */
    const hideItemsAgain = (list) => {
        const visibleListItems = list.querySelectorAll(settings.selector.visibleItems);

        visibleListItems.forEach((item, index) => {
            // give the element a height to change from
            item.style.height = item.scrollHeight + "px";

            // set the height back to 0
            window.setTimeout(function () {
                item.style.height = 0;
            }, 1);

            // when the transition is complete, hide it
            setTimeout(() => {
                item.classList.remove(settings.class.itemIsVisible);
                item.classList.add(settings.class.itemIsHidden);
            }, index * settings.animationDuration);
        });
    };

    /**
     * registers events on trigger and shows more items in the list
     *
     * @param {NodeList} list - the list elements
     * @param {Node} trigger - the trigger element
     */
    const registerEvents = (list, trigger) => {
        trigger.addEventListener('click', function (e) {
            e.preventDefault();
            toggleTrigger(trigger);

            if (e.currentTarget.classList.contains(settings.class.triggerIsActive)) {
                showMoreItems(list);
            } else {
                hideItemsAgain(list, trigger);
            }
        });
    };

    /**
     * show first few items, hide the rest
     *
     * @param {NodeList} list - the list elements
     */
    const setVisibleItems = (list) => {
        const numberOfVisibleItems = list.getAttribute(settings.attribute.defaultCount) || settings.defaultValueItems,
            listItems = list.children,
            lastChild = listItems.length - 1;

        for (let i = numberOfVisibleItems; i < lastChild; i++) {
            listItems[i].classList.add(settings.class.itemIsHidden);
        }

        listItems[lastChild].classList.remove(settings.class.itemIsHidden);
    };

    /**
     * get DOM elements and register events
     */
    const init = () => {
        const showMoreTrigger = document.querySelectorAll(`[${settings.attribute.trigger}]`);
        showMoreTrigger.forEach((trigger) => {
            if (trigger.dataset.alttext) {
                const showMoreListID = trigger.dataset.target,
                    showMoreList = document.getElementById(showMoreListID.substring(1, showMoreListID.length));

                setVisibleItems(showMoreList);
                registerEvents(showMoreList, trigger);
            }
        });
    };

    return {
        init: init
    };
})();
