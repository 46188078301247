/**
 * handles Tooltips
 * based on the "qtip" Library
 *
 * @param contentAttr
 */
const Tooltip = ((contentAttr) => {
    const settings = {
        selector: {
            tooltip:        ".c-tooltip",
            tooltipTrigger: ".js-tooltip, .html-tooltip", // use .js-tooltip if possible
            tooltipContent: ".c-tooltip__content, .tooltip-content",
            themePlausi:    ".theme-plausi"
        },
        class: {
            tooltip:     "c-tooltip",
            themePlausi: "theme-plausi"
        }
    };

    /**
     * Removes all Tooltips
     */
    const remove = () => {
        $(settings.selector.tooltipTrigger).qtip("remove");
    };
    
    /**
     * Hides all Tooltips
     */
    const hide = () => {
        $(settings.selector.tooltipTrigger).qtip("hide");
    };
    
    /**
     * Initializes tooltips library
     *
     * @param contentAttr
     */
    const init = (contentAttr) => {
        if (typeof $.fn.qtip === 'function') {
            $(settings.selector.tooltipTrigger).each(function () {
                const isPlausi = $(this).parents(settings.selector.themePlausi).length;
                let themeClass = "";

                if (isPlausi) {
                    themeClass = settings.class.themePlausi;
                }
                
                const extraClasses = $(this).data('additional-tooltip-classes') + " " + themeClass,
                    tooltipTitle = $(this).attr('title') || $(this).attr('data-title'),
                    tooltipContent = $(this).next(settings.selector.tooltipContent) || $(this).attr('tooltip-desc'),
                    tooltipPlacement = $(this).data('placement') || "right",
                    tooltipArrowPlacement = tooltipPlacement === "right" ? "left" : "right";

                $(this).qtip({
                    content: {
                        attr:  contentAttr,
                        title: tooltipTitle,
                        text:  tooltipContent
                    },
                    style: {
                        classes: settings.class.tooltip + " " + extraClasses
                    },
                    hide: {
                        when:  'mouseout',
                        delay: 300,
                        fixed: true
                    },
                    events: {
                        show: function() {
                            const $el = $(this);
                            $el.qtip('option', 'position.my', "top " + tooltipArrowPlacement || 'bottom center');
                            $el.qtip('option', 'position.at', "bottom " + tooltipPlacement || 'bottom center');
                        }
                    }
                });
            });
        }
    };
    
    return {
        init:   init,
        remove: remove,
        hide:   hide
    };
})();
