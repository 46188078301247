/**
 * @module Spinner
 * to display loading state e.g. after clicking a button
 * @type {{init: init}}
 */
const Spinner = (() => {
    /**
     *  Spinner Settings
     *
     * @typedef {object} Settings
     * @type {object}
     * @property {boolean} settings.showOnFocus        - Whether Spinner should be displayed as long as the button is focuseed
     * @property {string} settings.showMaxTime         - Number of milliseconds the Spinner should be displayed (if showOnFocus is false)
     * @property {string} selector.spinnerWrapper      - Selector used for identifying the parent of the spinner element
     * @property {string} selector.spinner             - Selector used for identifying the Spinner
     * @property {string} class.spinner                - Class used to identify the Spinner
     * @property {string} class.isHidden               - Class used to hide the Spinner
     * @property {boolean} class.eventTarget           - Class used to identify the target of the Click-Event-Listener
     */

    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        settings: {
            showOnFocus: false,
            showMaxTime: 4000
        },
        selector: {
            spinnerWrapper: "[data-spinner], .js-spinner",
            spinner:        ".c-spinner"
        },
        class: {
            spinner:     "c-spinner",
            isHidden:    "-is-hidden",
            eventTarget: "c-btn"
        }
    };

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} configuration
     */
    let config = {};

    /**
     * Click Function Event
     *
     * @param {Node} element - parent node of the spinner
     * @param {boolean} [showOnFocus=false] - if true: display spinner as long as element is focused
     * @param {number} [showMaxTime=4000] - number of milliseconds the spinner should be displayed
     */
    const clickFunction = (element, showOnFocus, showMaxTime) => {
        displaySpinner(element);

        if (!showOnFocus) {
            setTimeout(() => {
                hideSpinner(element);
            }, showMaxTime);
        }
    };

    /**
     * Removes Click-Event-Listener
     *
     * @function
     * @public
     * @param {Node} element - parent node of the spinner
     */
    const removeEventListener = (element) => {
        if (hasSpinner(element)) {
            element.removeEventListener("click", clickFunction, false);
        }
    };

    /**
     * Add spinner to a parent/wrapper element
     *
     * @function
     * @public
     * @param {Node} element - parent node of the spinner
     * @param {boolean} [showOnFocus=false] - if true: display spinner as long as element is focused
     * @param {number} [showMaxTime=4000] - number of milliseconds the spinner should be displayed
     * @param extraClass
     */
    const addToElement = (element, showOnFocus = config.settings.showOnFocus, showMaxTime = config.settings.showMaxTime, extraClass = "h-pos-absolute") => {
        const spinnerHTML = `<span class="${config.class.spinner} ${config.class.isHidden} ${extraClass}"></span>`;
        if (!Spinner.hasSpinner(element)) {
            element.insertAdjacentHTML('afterbegin', spinnerHTML);
            registerEvents(element, showOnFocus, showMaxTime);
        }
    };

    /**
     * Checks if spinner element already exists
     *
     * @function
     * @public
     * @param {Node} element - parent node of the spinner
     * @returns {boolean} - whether Spinner element already exists
     */
    const hasSpinner = (element) => {
        if (element.querySelectorAll(config.selector.spinner).length) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Hide spinner within parent element
     *
     * @function
     * @public
     * @param {Node} element - parent node of the spinner
     */
    const hideSpinner = (element) => {
        element.classList.remove('h-pos-relative');

        if (Spinner.hasSpinner(element)) {
            element.querySelector(config.selector.spinner).classList.add(config.class.isHidden);
        }
    };

    /**
     * Display spinner within parent element
     *
     * @function
     * @public
     * @param {Node} element - parent node of the spinner
     */
    const displaySpinner = (element) => {
        element.classList.add('h-pos-relative');

        if (Spinner.hasSpinner(element)) {
            element.querySelector(config.selector.spinner).classList.remove(config.class.isHidden);
        }
    };

    /**
     * Registers all events on specified elements (default: buttons)
     *
     * @param {Node} element - parent node of the spinner
     * @param {boolean} [showOnFocus=false] - if true: display spinner as long as element is focused
     * @param {number} [showMaxTime=4000] - number of milliseconds the spinner should be displayed
     */
    const registerEvents = (element, showOnFocus = config.settings.showOnFocus, showMaxTime = config.settings.showMaxTime) => {
        if (Spinner.hasSpinner(element) && element.classList.contains(config.class.eventTarget)) {
            element.addEventListener('click',
                function () {
                    clickFunction(element, showOnFocus, showMaxTime);
                },
                false);

            if (showOnFocus) {
                element.addEventListener('blur', function () {
                    hideSpinner(element);
                });
            }
        }
    };

    /*
     * Initialize the module with custom settings
     *
     * @method
     * @public
     *
     * @param {Settings} settings - The settings that will overwrite the default settings
     */
    const init = (settings) => {
        config = $.extend(true, {}, defaults, settings);
        const spinnerWrapper = document.querySelectorAll(config.selector.spinnerWrapper);

        if (spinnerWrapper.length) {
            spinnerWrapper.forEach((element) => {
                Spinner.addToElement(element, false, config.settings.defaultTime);
            });
        }
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */
    return {
        init:                init,
        hasSpinner:          hasSpinner,
        hideSpinner:         hideSpinner,
        displaySpinner:      displaySpinner,
        addToElement:        addToElement,
        removeEventListener: removeEventListener,
        registerEvents:      registerEvents
    };
})();
