/**
 * Datepicker
 *
 * based on jQuery UI DatePicker
 *
 * @author "Laura Redeker" <redeke@pharma4u.de>
 * @type {{init: init}}
 * @property {object} selector
 * @module Datepicker
 */

const Datepicker = (($) => {
    /**
     * Datepicker Settings
     *
     *
     * @typedef {object} Settings
     * @property
     */

    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        selector: {
            datepicker:  ".js-datepicker, .datepicker",
            themePlausi: ".theme-plausi"
        },
        class: {
            themePlausi: "theme-plausi"
        },
        data: {
            defaults: {
                clearText:     'löschen',
                clearStatus:   'aktuelles Datum löschen',
                closeText:     'schließen',
                closeStatus:   'ohne Änderungen schließen',
                prevText:      'zurück',
                prevStatus:    'letzten Monat zeigen',
                nextText:      'vor',
                nextStatus:    'nächsten Monat zeigen',
                currentText:   'heute',
                currentStatus: '',
                monthNames:    ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
                    'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun',
                    'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                monthStatus:   'anderen Monat anzeigen',
                yearStatus:    'anderes Jahr anzeigen',
                weekHeader:    'Wo',
                weekStatus:    'Woche des Monats',
                dayNames:      ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                dayNamesMin:   ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                dayStatus:     'Setze DD als ersten Wochentag',
                dateStatus:    'Wähle D, M d',
                dateFormat:    'dd.mm.yy',
                firstDay:      1,
                initStatus:    'Wähle ein Datum',
                isRTL:         false
            }
        }
    };

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} Konfiguration
     */
    let config = {};

    /**
     * @type {object} Öffentliche Members
     */
    const exports = {};

    /*
     * Initialize the module with custom settings
     *
     * @method
     * @public
     * @param {Settings} settings - The settings that will overwrite the default settings
     */
    exports.init = (settings) => {
        config = $.extend(true, {}, defaults, settings);

        // Datepicker
        if (typeof $.datepicker !== 'undefined') {
            $.datepicker.regional.de = config.data.defaults;

            // set default settings
            $.datepicker.setDefaults($.datepicker.regional.de);

            const $datepicker = $(config.selector.datepicker);

            // default datepicker
            $datepicker.datepicker({
                beforeShow: function (input) {
                    /*
                     * adds theme class if button is in plausi
                     */
                    const isPlausi = $(input).parents(config.selector.themePlausi).length;

                    if (isPlausi) {
                        $("#ui-datepicker-div").addClass(config.class.themePlausi);
                    }
                },
                onSelect: function () {
                    Form.activateFloat($datepicker[0]);
                    this.dispatchEvent(new CustomEvent("change"));
                },
                onClose: function () {
                    Form.activateFloat($datepicker[0]);
                }
            });
        }
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */
    return exports;
})($);

