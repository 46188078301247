/**
 * @module Accordion
 * to toggle the visibility of content across your project (list of collapsible elements)
 *
 * @author "Laura Redeker" <redeke@pharma4u.de>
 * @property {object} selector
 */
// eslint-disable-next-line no-unused-vars
const Accordion = ((document) => {
    /**
     * Accordion Settings
     *
     * @typedef {object} Settings
     *
     * @property {string} selector.accordionWrapper     - The Accordion Widget / Group
     * @property {string} selector.accordionHeader    - The clickable Accordion Header
     * @property {string} selector.accordionPanel     - The toggled Accordion Panel
     * @property {string} selector.accordionOpen      - Any open Accordion Panels
     * @property {string} selector.accordionDisabled  - Any disabled Accordion Headers
     * @property {string} attribute.multiSelectable     - Indicates whether multiple Accordions can be selected
     * @property {string} attribute.expanded            - Indicates the state of the Accordion Header
     * @property {string} attribute.selected            - Indicated the state of the Accordion Header
     * @property {string} attribute.hidden              - Indicates the state of the Accordion Panel
     * @property {string} class.isActive                - Class used for Styling a selected Accordion
     */

    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        selector: {
            accordionWrapper:  ".js-accordion-group",
            accordionHeader:   "[role='tab']",
            accordionPanel:    "[role='tabpanel']",
            accordionOpen:     "[aria-expanded='true']",
            accordionDisabled: "[aria-disabled='true']"
        },
        attribute: {
            multiSelectable: "aria-multiselectable",
            expanded:        "aria-expanded",
            selected:        "aria-selected",
            hidden:          "aria-hidden"
        },
        class: {
            isActive: "-is-active"
        }
    };

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} Konfiguration
     */
    let config = {};

    // UI FUNCTIONS (Internal) =================================================

    /**
     * Initially open accordions with the attribute "aria-expanded=true"
     *
     * @function
     * @private
     * @param {Node} accordionGroup - The current accordion group
     */
    const setDefaultView = (accordionGroup) => {
        const openHeaders = accordionGroup.querySelectorAll(config.selector.accordionOpen);

        openHeaders.forEach(openHeader => {
            const openPanel = openHeader.parentNode.nextElementSibling;
            openHeader.classList.add(config.class.isActive);
            openPanel.classList.add(config.class.isActive);
        });
    };

    /**
     * Determine the configuration of the current accordion group
     * e.g. close all other accordions if accordion group isn't multiselectable
     *
     * @param {Node} accordionGroup - The current accordion group
     * @param {NodeList} accordionHeaders - All headers within the current accordion group
     */
    const checkAccordionType = (accordionGroup, accordionHeaders) => {
        const accordionPanels = accordionGroup.querySelectorAll(config.selector.accordionPanel),
            isMultiSelectable = accordionGroup.getAttribute(config.attribute.multiSelectable) === "true";

        if (!isMultiSelectable) {
            Util.setClass(accordionHeaders, config.class.isActive, "remove");
            Util.setClass(accordionPanels, config.class.isActive, "remove");
        }
    };

    /**
     * Toggles the accordion's visibility classes and ARIA-attributes in a accordionGroup
     *
     * @function
     * @private
     * @param {Node} accordionGroup - The current accordion group
     */
    const toggleAccordion = (accordionGroup) => {
        const accordionHeaders = accordionGroup.querySelectorAll(`${config.selector.accordionHeader}:not(${config.selector.accordionDisabled})`);

        accordionHeaders.forEach(accordionHeader => {
            accordionHeader.addEventListener("click", function (e) {
                const currentHeader = e.target,
                    currentPanel = currentHeader.parentNode.nextElementSibling,
                    isActive = currentHeader.classList.contains(config.class.isActive);

                checkAccordionType(accordionGroup, accordionHeaders);

                // update the Styling/Visibility Classes of the current Accordion
                if (isActive) {
                    currentHeader.classList.remove(config.class.isActive);
                    currentPanel.classList.remove(config.class.isActive);
                } else {
                    currentHeader.classList.add(config.class.isActive);
                    currentPanel.classList.add(config.class.isActive);
                }

                // update ARIA-Attributes of the current Accordion
                currentHeader.setAttribute(config.attribute.expanded, !isActive);
                currentHeader.setAttribute(config.attribute.selected, !isActive);
                currentPanel.setAttribute(config.attribute.hidden, isActive);
            });
        });
    };

    /**
     * Register any required event listeners
     *
     * @function
     * @private
     */
    const registerEvents = () => {
        const accordions = document.querySelectorAll(config.selector.accordionWrapper);

        accordions.forEach((element) => {
            setDefaultView(element);
            toggleAccordion(element);
        });
    };

    /**
     * @type {object} Öffentliche Members
     */
    const exports = {};

    /*
     * Initialize the module with custom settings
     *
     * @method
     * @public
     * @param {Settings} settings - The settings that will overwrite the default settings
     */
    exports.init = (settings) => {
        config = $.extend(true, {}, defaults, settings);
        registerEvents();
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */
    return exports;
})(document);
