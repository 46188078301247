/**
 * Utility Classes
 *
 * @type {{init: init}}
 */
const Util = ((document) => {
    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        selector: {},
        class:    {
            hide: "h-hide",
            show: "h-show"
        }
    };

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} Konfiguration
     */
    let config = {};

    /**
     * @type {object} Öffentliche Members
     */
    const exports = {};

    /**
     * Toggle Visibility of an element
     *
     * @param {NodeList} el - Node element
     */
    exports.toggleVisibility = (el) => {
        if (el.classList.contains(config.class.hide)) {
            el.classList.remove(config.class.hide);
            el.classList.add(config.class.show);
        } else if (el.classList.contains(config.class.show)) {
            el.classList.remove(config.class.show);
            el.classList.add(config.class.hide);
        }
    };

    /**
     * Hide an element with 'h-hide' helper class
     *
     * @param {NodeList} el - Node element
     */
    exports.hide = (el) => {
        if (el.classList.contains(config.class.show)) {
            el.classList.remove(config.class.show);
        }
        el.classList.add(config.class.hide);
    };

    /**
     * Display an element with 'h-show' helper class
     *
     * @param {NodeList} el - Node element
     */
    exports.show = (el) => {
        if (el.classList.contains(config.class.hide)) {
            el.classList.remove(config.class.hide);
        }
        el.classList.add(config.class.show);
    };

    /**
     * Set a Class for all elements in an Array
     *
     * @param {NodeList} els - All selected elements.
     * @param {string} className - Class name that should be set.
     * @param {string} fnName - classList method name (add, remove, toggle..)
     */
    exports.setClass = (els, className, fnName) => {
        els.forEach(node => {
            node.classList[fnName](className);
        });
    };

    /**
     * Get the natural height of a (hidden) element
     *
     * @param {Node} item
     * @returns {string} height of the item in px
     */
    exports.getHeight = (item) => {
        item.style.display = 'block'; // Make it visible
        const height = item.scrollHeight + 'px';
        item.style.display = ''; //  Hide it again
        return height;
    };

    /**
     * Wait for the DOM to fully load before e.g. attaching events to objects in the DOM
     * Replaces to jQuery's $(document).ready()
     * https://tobiasahlin.com/blog/move-from-jquery-to-vanilla-javascript/#document-ready
     *
     * @param callback
     */
    exports.ready = (callback) => {
        if (document.readyState !== "loading") {
            callback();
        } else {
            document.addEventListener("DOMContentLoaded", callback);
        }
    };

    /*
     * Initialize the module with custom settings
     *
     * @method
     * @public
     * @param {Settings} settings - The settings that will overwrite the default settings
     */
    exports.init = (settings) => {
        config = $.extend(true, {}, defaults, settings);
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */
    return exports;
})(document);
