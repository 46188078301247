/**
 * Collapse
 * to toggle the visibility of content across your project
 *
 * @author "Laura Redeker" <redeke@pharma4u.de>
 * @type {{init: init}}
 * @property {object} selector
 *
 * @module Collapse
 */
const Collapse = ((document) => {
    /**
     *  Collapse Settings
     *
     * @typedef {object} Settings
     *
     * @type {object}
     * @property {string} selector.collapseTrigger     - Selector used for identifying the collapsible element
     * @property {string} class.collapseTrigger        - Class used for identifying the collapsible element
     * @property {string} class.isActive               - Class used for styling an active trigger element
     * @property {string} class.isVisible              - Class used for styling an expanded collapse element
     * @property {string} attribute.target             - Attribute that contains the collapsible target
     * @property {string} attribute.extraClass         - Attribute that contains an extra class that is being toggled
     * @property {boolean} attribute.triggerExtraClass - The extra class will be toggled for the trigger element as well if this attribute exists
     */

    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        selector: {
            collapseTriggerWithoutCheckboxes: ".js-collapse:not(.c-collapse):not(.c-checkbox)"
        },
        class: {
            collapseTrigger: "js-collapse",
            isActive:        "-is-active",
            isVisible:       "-is-visible",
            arrowIcon:       "-icon-arrow",
            isCheckbox:      "c-checkbox"
        },
        attribute: {
            target:            "data-target",
            extraClass:        "data-extra-class",
            triggerExtraClass: "data-trigger-extra-class"
        },
        event: {
            open:   "collapse:open",
            close:  "collapse:close",
            toggle: "collapse:toggle"
        }
    };

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} configuration
     */
    let config = {};

    /**
     * Closes target element and updates trigger.
     *
     * @function
     * @public
     *
     * @param {Node} trigger - The element that triggers the panel (optional).
     * @param {Node} target - The target panel that is toggled.
     * @param {string} extraClass - Custom class that can be added to target element.
     * @param {boolean} triggerExtraClass - Whether the extra class should be toggled on the trigger element as well,
     */
    const close = (trigger, target, extraClass, triggerExtraClass) => {
        if (!target && trigger) {
            target = document.querySelector(trigger.getAttribute(config.selector.dataTarget));
        }

        // update trigger
        if (trigger) {
            trigger.classList.remove(config.class.isActive);
        }

        // close target element and remove custom class
        if (target) {
            target.classList.remove(config.class.isVisible);
            if (extraClass) {
                target.classList.remove(extraClass);

                // remove custom class on trigger element
                if (triggerExtraClass) {
                    trigger.classList.remove(extraClass);
                }
            }

            // close event
            const closeEvent = new CustomEvent(config.event.close, {
                'detail': {
                    trigger: trigger
                }
            });

            target.dispatchEvent(closeEvent);
        }
    };

    /**
     * Opens target element and updates trigger.
     *
     * @function
     * @public
     *
     * @param {Node} trigger - The element that triggers the panel (optional).
     * @param {Node} target - The target panel that is toggled (optional).
     * @param {string} extraClass - Custom class that can be added to target element
     * @param {boolean} triggerExtraClass - Whether the extra class should be toggled on the trigger element as well,
     */
    const open = (trigger, target, extraClass, triggerExtraClass) => {
        if (!target && trigger) {
            target = document.querySelector(trigger.getAttribute(config.attribute.target));
        }

        // update trigger
        if (trigger) {
            trigger.classList.add(config.class.isActive);
        }

        // open target element and add custom class
        if (target) {
            target.classList.add(config.class.isVisible);

            if (extraClass) {
                target.classList.add(extraClass);

                // remove custom class on trigger element
                if (triggerExtraClass) {
                    trigger.classList.add(extraClass);
                }
            }

            // open event
            const openEvent = new CustomEvent(config.event.open, {
                'detail': {
                    trigger: trigger
                }
            });

            target.dispatchEvent(openEvent);
        }
    };

    /**
     * Toggles the visibility of the target element
     *
     * @function
     * @public
     *
     * @param {Node} trigger - The element that triggers the panel.
     * @param {Node} target - The target panel that is toggled.
     * @param {string} extraClass - Custom class that can be toggled with the target element.
     * @param {boolean} triggerExtraClass - Whether the extra class should be toggled on the trigger element as well,
     */
    const toggleVisibility = function (trigger, target, extraClass, triggerExtraClass) {
        if (!target && trigger) {
            const dataTarget = trigger.getAttribute(config.attribute.target);
            target = document.querySelector(dataTarget);
        }

        // toggle event
        const toggleEvent = new CustomEvent(config.event.toggle, {
            'detail': {
                trigger: trigger
            }
        });

        // collapse target element
        if (target) {
            if (target.classList.contains(config.class.isVisible)) {
                close(trigger, target, extraClass, triggerExtraClass);
                target.dispatchEvent(toggleEvent);
            } else {
                open(trigger, target, extraClass, triggerExtraClass);
                target.dispatchEvent(toggleEvent);
            }
        } else {
            console.warn("data-target has not been set for .js-collapse (data-target='#targetSelector') or target is missing!");
        }
    };

    /**
     * Activates all Checkbox-Collapses
     *
     * adds change-event listener to checkboxes
     * opens collapse of active checkboxes
     */
    const activateCheckboxCollapse = function () {
        const collapseCheckboxes = document.querySelectorAll(`.${config.class.collapseTrigger}.${config.class.isCheckbox}`);

        collapseCheckboxes.forEach(el => {
            const checkbox = el.querySelector("input[type='checkbox']"),
                target = document.querySelector(el.getAttribute(config.attribute.target)),
                isChecked = checkbox.getAttribute("checked") !== null;

            // open collapse if checkbox is checked per default
            if (isChecked) {
                open(el);
            }

            if (target) {
                checkbox.addEventListener('change', () => {
                    toggleVisibility(checkbox, target);
                });
            } else {
                console.warn("data-target has not been set for .js-collapse (data-target='#targetSelector') or target is missing!");
            }
        });
    };

    /**
     * Registers event listeners
     *
     * @function
     * @public
     */
    const registerEvents = function () {
        const triggerElements = document.querySelectorAll(config.selector.collapseTriggerWithoutCheckboxes);

        triggerElements.forEach(trigger => {
            trigger.addEventListener('click', function (e) {
                e.stopPropagation();
                const target = document.querySelector(e.currentTarget.getAttribute(config.attribute.target));
                const extraClass = e.currentTarget.getAttribute(config.attribute.extraClass);
                const triggerExtraClass = e.currentTarget.hasAttribute(config.attribute.triggerExtraClass);
                toggleVisibility(e.currentTarget, target, extraClass, triggerExtraClass);
            });
        });
    };

    /*
     * Initialize the module with custom settings
     *
     * @method
     * @public
     * 
     * @param {Settings} settings - The settings that will overwrite the default settings
     */
    const init = (settings) => {
        config = $.extend(true, {}, defaults, settings);
        registerEvents();
        activateCheckboxCollapse();
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */
    return {
        init:           init,
        open:           open,
        close:          close,
        toggle:         toggleVisibility,
        registerEvents: registerEvents
    };
})(document);
