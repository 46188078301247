/**
 * @module SelectList
 * @author "Laura Redeker" <redeke@pharma4u.de>
 *
 * TODO: implement SEAF Configuration Pattern
 * TODO: use ARIA- oder data-attributes as selectors (refactoring!)
 *
 * @type {{init: init}}
 */
const SelectList = (() => {
    const settings = {
        selector: {
            selectTrigger:      ".js-select",
            selectList:         ".c-list",
            selectListItem:     ".c-list__item",
            selectActiveItem:   ".c-list__item.-is-active",
            selectListItemLink: ".c-link",
            panel:              ".c-panel"
        },
        class: {
            selectIsActive:   "-is-active",
            hasExternalLinks: "js-external-links"
        }
    };

    /**
     * handles the click event and updates trigger content
     *
     * @param {Node} trigger - trigger element
     * @param {Node} list - list element
     */
    const handleClickEvent = (trigger, list) => {
        const panel = list.closest(settings.selector.panel),
            listItem = list.querySelectorAll(settings.selector.selectListItem);

        listItem.forEach((item) => {
            item.addEventListener("click", (e) => {
                const currentItem = e.currentTarget.closest(settings.selector.selectListItem);
                const activeItem = list.querySelector(settings.selector.selectActiveItem);

                if (activeItem) {
                    activeItem.classList.remove(settings.class.selectIsActive);
                }

                Panel.close(panel, trigger);
                // update text in button
                trigger.innerHTML = currentItem.querySelector(settings.selector.selectListItemLink).innerHTML;
                currentItem.classList.add(settings.class.selectIsActive);

                if (!list.classList.contains(settings.class.hasExternalLinks)) {
                    e.preventDefault();
                }
            });
        });
    };

    /**
     * get DOM elements
     */
    const init = () => {
        const selectTrigger = document.querySelectorAll(settings.selector.selectTrigger);

        selectTrigger.forEach((trigger) => {
            const panelID = trigger.dataset.target,
                panel = document.getElementById(panelID.substring(1, panelID.length)),
                list = panel.querySelector(settings.selector.selectList);

            handleClickEvent(trigger, list);
        });
    };

    return {
        init: init
    };
})
();
