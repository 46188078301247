/**
 * Tabs: jQuery UI Tabs library
 *
 * @author Charlotte Prieß <priess@pharma4u.de>
 * @module Tab
 */
const Tab = (() => {
    /**
     *  Module Settings
     *
     * @typedef {object} Settings
     *
     * @type {object}
     * @property {object} selector DOM-Selektor(en)
     */

    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        selector: {
            tabContainer: ".js-tabs"
        },
        class: {
            activeClass: "-is-active"
        }
    };

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} Konfiguration
     */
    let config = {};

    /**
     * @type {object} Öffentliche Members
     */
    const exports = {};

    /**
     * Initialize the module with custom settings
     *
     * @method
     * @public
     * @param {Settings} settings - The settings that will overwrite the default settings
     */
    exports.init = (settings) => {
        config = $.extend(true, {}, defaults, settings);

        const $tabContainer = $(defaults.selector.tabContainer);
            
        if ($tabContainer.length) {
            $tabContainer.tabs();
        }
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */
    return exports;
})();
