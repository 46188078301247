/**
 * Flyout Navigation (Primary Navigation)
 *
 * @type {{init: init}}
 */
const Navigation = (() => {
    const settings = {
        selector: {
            flyout:                 ".c-nav__flyout",
            currentFlyout:          ".c-nav__flyout.-is-visible",
            isActive:               ".-is-active",
            isVisible:              ".-is-visible",
            isHidden:               ".-is-hidden",
            navigationLink:         ".c-nav__item__link",
            firstLevelItem:         ".c-nav__item.-bar",
            firstLevelItemLink:     ".c-nav__item.-bar > .c-nav__item__link",
            firstLevelSearch:       ".c-nav__item.-bar.-is-search",
            searchInput:            ".c-search",
            secondLevelNav:         ".c-nav__level2",
            secondLevelSearch:      ".c-nav__level2__searchform",
            currentSecondLevelNav:  ".c-nav__level2.-is-visible",
            secondLevelItems:       ".c-nav__item.-secondlevel",
            thirdLevelList:         ".c-nav__item__links",
            secondLevelDescription: ".c-nav__description",
            thirdLevelImagebox:     ".c-nav__level3__imagebox",
            thirdLevelNav:          ".c-nav__level3",
            currentThirdLevelNav:   ".c-nav__level3.-is-visible"
        },
        class: {
            isActive:   "-is-active",
            isVisible:  "-is-visible",
            isHidden:   "-is-hidden",
            isSearch:   "-is-search",
            isOverview: "-is-overview"
        }
    };

    /**
     * unselect currently selected item and hide visible submenu
     *
     * @param $currentFlyout
     */
    const unselectItems = ($currentFlyout) => {
        const $currentSecondLevelItem = $currentFlyout.find(settings.selector.secondLevelItems);
        const $currentThirdLevelNav = $currentSecondLevelItem.children(settings.selector.thirdLevelNav);

        $currentSecondLevelItem.removeClass(settings.class.isActive);
        $currentThirdLevelNav.removeClass(settings.class.isVisible).addClass(settings.class.isHidden);
    };

    /**
     * selects first item in submenu
     *
     * @param $currentFlyout
     * @param $item
     */
    const selectItem = ($currentFlyout, $item) => {
        const $thirdLevelNav = $item.find(settings.selector.thirdLevelNav),
            $otherItems = $item.parent().children();

        // select first item, show sub-nav
        $otherItems.removeClass(settings.class.isActive);
        $item.addClass(settings.class.isActive);
        $thirdLevelNav.removeClass(settings.class.isHidden).addClass(settings.class.isVisible);
    };

    /**
     * sets height of the flyout container to the height of the 3rd level navigation
     *
     * @param $currentFlyout
     * @param $flyout
     */
    const setContainerHeight = ($currentFlyout) => {
        const $firstLevelItemLink = $currentFlyout.prev(settings.selector.navigationLink).parent();
        const $secondSubNav = $(settings.selector.secondLevelNav, $currentFlyout);
        const $thirdSubNav = $(settings.selector.currentThirdLevelNav, $secondSubNav);

        let colContainer = [];

        if ($currentFlyout.hasClass(settings.class.isOverview)) {
            colContainer.push($secondSubNav);
            colContainer.push($thirdSubNav);
            colContainer.push($(settings.selector.thirdLevelList, $thirdSubNav));
            colContainer.push($(settings.selector.thirdLevelImagebox, $thirdSubNav));
        } else if ($firstLevelItemLink.hasClass(settings.class.isSearch)) {
            colContainer.push($(settings.selector.secondLevelSearch), $currentFlyout);
        } else {
            colContainer.push($(settings.selector.secondLevelDescription, $currentFlyout));
            colContainer.push($(settings.selector.thirdLevelNav, $currentFlyout));
            colContainer.push($currentFlyout);
        }

        /* Get the natural height of the element */
        const getHeight = (el) => {
            return $(el).outerHeight();
        };

        let colHeight = [];

        colContainer.forEach(function (container) {
            colHeight.push(getHeight(container));
        });

        let height = Math.max(...colHeight);

        $currentFlyout.height(height + 'px');
        $thirdSubNav.removeClass(settings.class.isHidden);
    };

    /**
     * register events on navigation
     *
     * @param $currentFlyout
     */
    const bindEvents = ($currentFlyout) => {
        const $firstLevelItemLink = $currentFlyout.prev(settings.selector.navigationLink).parent(),
            $oldActiveLink = $firstLevelItemLink.parent().find(settings.selector.isActive),
            $secondLevelLinks = $currentFlyout.find(settings.selector.secondLevelItems).children(settings.selector.navigationLink),
            $flyout = $firstLevelItemLink.next(settings.selector.flyout),
            $firstLevelSearch = $(settings.selector.firstLevelSearch),
            $flyoutSearch = $firstLevelSearch.find(settings.selector.flyout),
            $flyoutSearchInput = $flyoutSearch.find(settings.selector.searchInput);

        // select first element in overview and set container height
        $firstLevelItemLink.on('mouseover focus click', function (e) {
            $(e.currentTarget).addClass(settings.class.isActive);

            // to prevent Firefox-Bug and trigger focus on flyout display
            if ($(e.currentTarget).hasClass(settings.class.isSearch)) {
                $flyoutSearch.addClass(settings.class.isVisible);
                $flyoutSearchInput.focus();
            } else {
                $flyoutSearch.removeClass(settings.class.isVisible);

                // only set the height of other flyouts other than search
                setContainerHeight($currentFlyout);
            }

            if ($flyout.hasClass(settings.class.isOverview)) {
                const $firstItem = $flyout.find(settings.selector.secondLevelItems).first();
                unselectItems($flyout);
                selectItem($flyout, $firstItem);
            } else {
                $oldActiveLink.removeClass(settings.class.isActive);
            }
        });

        // set active class to initially selected item
        $firstLevelItemLink.on('mouseout', function (e) {
            const relatedAttribute = $(e.relatedTarget).closest(settings.selector.flyout).attr('role');
            if (!$flyout.hasClass(settings.class.isVisible)) {
                if (!$firstLevelItemLink.hasClass(settings.class.isOverview)) {
                    $firstLevelItemLink.removeClass(settings.class.isActive);
                    $oldActiveLink.addClass(settings.class.isActive);
                } else {
                    // -is-overview need a special treatment
                }
            }

            if (relatedAttribute === undefined) {
                $flyoutSearch.removeClass(settings.class.isVisible);
            }
        });

        // select current item, set height of container div
        $secondLevelLinks.each(function () {
            $(this).on('mouseover click', function (e) {
                const $selectedItem = $(e.target),
                    $currentItem = $selectedItem.parent(),
                    $flyout = $selectedItem.closest(settings.selector.flyout);

                unselectItems($flyout);
                selectItem($flyout, $currentItem);
                setContainerHeight($flyout);
            });
        });
    };

    /**
     * get DOM elements
     */
    const init = () => {
        const $flyoutNav = $(settings.selector.flyout);
        $flyoutNav.removeClass(settings.class.isVisible);

        $flyoutNav.each(function () {
            bindEvents($(this));
        });
    };

    return {
        init: init
    };
})();
