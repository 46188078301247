/**
 * Validate
 *
 * @author Laura Redeker <redeker@pharma4u.de>
 * @module Validate
 */
const Validate = (() => {
    /**
     *  Module Settings
     *
     * @typedef {object} Settings
     *
     * @type {object}
     * @property {object} selector DOM-Selektor(en)
     * @property {object} class Verwendete CSS-Klassen
     * @property {object} attribute HTML-Attribute (z.B. data, aria)
     * @property {object} callback Callback-Funktionen
     * @property {SampleModuleUpdate} callback.update Callback für Modul-Update
     */

    /**
     * Default values that can be overwritten via {init(settings)}
     *
     * @type {Settings}
     * @constant
     */
    const defaults = {
        selector: {
            inputFields: ".c-input.-old, .c-textarea.-old, .c-select.-old"
        },
        class: {
            hasChanged: "-has-changed"
        }
    };

    /**
     * The internal configuration that should be used inside this module.
     *
     * @type {Settings} Konfiguration
     */
    let config = {};

    /**
     * @type {object} Öffentliche Members
     */
    const exports = {};

    /**
     * Bind all input events
     *
     * @param {Node} element - the input/select/textarea.. element
     */
    const bindEvents = (element) => {
        element.addEventListener('change', function() {
            element.classList.add(config.class.hasChanged);
        });

        element.addEventListener('blur', function() {
            element.classList.add(config.class.hasChanged);
        });
    };

    /*
     * Initialize the module with custom settings
     *
     * @method
     * @public
     * @param {Settings} settings - The settings that will overwrite the default settings
     */
    exports.init = (settings) => {
        config = Object.assign({}, defaults, settings);

        const inputs = document.querySelectorAll(config.selector.inputFields);

        if (!inputs.length) {
            return;
        }

        inputs.forEach((input) => {
            bindEvents(input);
        });
    };

    /**
     * Public access to the module
     *
     * Any access point to this module to interact with other modules.
     */
    return exports;
})();
