/**
 * Open modal dialog for login and set action attribute of login form
 */
const LoginModal = ((document) => {
    const settings = {
        selector: {
            modal:    "#loginModal",
            form:     "#loginModalForm",
            triggers: ".accessRestrictedLink, .accessRestrictedNavLink"
        },
        class: {
            trigger:    "accessRestrictedLink",
            navTrigger: "accessRestrictedNavLink"
        }
    };

    /**
     * opens modal dialog for login and set action attribute of login form
     *
     * @param form Login Form
     * @param element Event target
     */
    const clickHandlerTrigger = (form, element) => {
        const re = /\?return_url=([^&]*)/g,
            redirect = re.exec(element.getAttribute("href")),
            target = element.getAttribute('target');

        if (redirect) {
            form.setAttribute("action", decodeURIComponent(redirect[1]));
        } else {
            form.setAttribute("action", element.getAttribute("href"));
        }
        if (target) {
            form.setAttribute("target", target);
        } else {
            form.removeAttribute("target");
        }
    };

    /**
     * opens modal dialog for login and set action attribute of login form in navigation
     *
     * @param form Login Form
     * @param element Event target
     */
    const clickHandlerTriggerNav = (form, element) => {
        const re1 = /\?return_url=([^&]*)/g,
            re2 = /praktikanten\/3-stex\/trainer/g,
            redirect1 = re1.exec(element.getAttribute("href")),
            target = element.getAttribute('target');
        let action;

        // set action value
        if (redirect1) {
            const redirect = re1.exec(decodeURIComponent(redirect1[1]));
            action = decodeURIComponent(redirect[1]);
        } else {
            action = element.getAttribute("href");
        }
        if (re2.exec(action)) {
            action = 'http://www.pharma4u.de/' + action;
        }

        // set action attribute
        form.setAttribute("action", action);
        if (target) {
            form.setAttribute("target", target);
        } else {
            form.removeAttribute("target");
        }
    };

    /**
     * Register events in Login Modal
     *
     * @param loginModal
     * @param form
     * @param triggers
     * @param element
     */
    const bindEvents = (loginModal, form, triggers) => {
        /**
         * open modal and set action attribute
         *
         * @param event
         */
        const openModalSetAction = (event) => {
            Panel.open(loginModal);
            // don't set action attributes if modal contains specific element
            if (loginModal.querySelector('.tx-felogin-pi1')) {
                return;
            } else {
                if (event.target.classList.contains(settings.class.trigger)) {
                    clickHandlerTrigger(form, event.target);
                } else if (event.target.classList.contains(settings.class.navTrigger)) {
                    clickHandlerTriggerNav(form, event.target);
                }
            }
        };

        triggers.forEach(function (trigger) {
            trigger.addEventListener("click", function (e) {
                e.preventDefault();
                openModalSetAction(e);
            });
        });
    };

    /**
     * Reloads current page after the login form is successfully submitted to a _blank window
     *
     * @param modal
     * @param form
     */
    const submitLoginForm = (modal, form) => {
        if (form) {
            const target = form.getAttribute('target');

            if (target === "_blank") {
                form.on('submit', function (e) {
                    Panel.close(form, e.currentTarget);
                    const oldCookie = document.cookie,
                        ri = setInterval(function () {
                            if (oldCookie !== document.cookie) {
                                clearInterval(ri);
                                window.location.reload();
                            }
                        }, 2000);
                });
            }
        }
    };

    /**
     * get DOM elements
     */
    const init = () => {
        const loginModal = document.querySelector(settings.selector.modal);
        if (loginModal) {
            const loginModalForm = loginModal.querySelector(settings.selector.form),
                triggers = document.querySelectorAll(settings.selector.triggers);
            bindEvents(loginModal, loginModalForm, triggers);
            submitLoginForm(loginModal, loginModalForm);
        }
    };

    return {
        init: init
    };
})(document);
